<template>
  <v-col cols='12' lg='6' md='6' sm='6' xl='6' xs='12'>
    <v-card color='white' elevation='0' height='100%' width='100%'>
      <v-container class='pl-15 pr-15' fill-height>
        <v-row
          align='center'
          align-content='center'
          class='text-center'
          justify='center'
          no-gutters
        >
          <v-col class='mb-8' cols='12'>
            <div class='text-h4 font-weight-black grey--text text--darken-1'>
              Account
            </div>
            <div class='text-h4 font-weight-black grey--text text--darken-1'>
              Confirmation
            </div>
            <div class='text-h4 font-weight-black grey--text text--darken-1'>
              Email
            </div>
          </v-col>

          <v-col cols='8'>
            <div class='subtitle-1 grey--text text--darken-2'>
              Your account has been verified!.
            </div>
          </v-col>
          <v-col class='mt-1' cols='8'></v-col>
          <v-col cols='8'>
            <div class='subtitle-1 grey--text text--darken-2'>
              You can now access the MCBA platform by using your email address
              and the password you just created. Please login using the link
              below.
            </div>
          </v-col>

          <v-col class='mt-8' cols='8'>
            <v-btn
              block
              class='white--text body-1 text-capitalize'
              color='primary'
              elevation='0'
              x-large
              @click='access'
            >
              Access Now
            </v-btn>
          </v-col>
        </v-row>
      </v-container>
    </v-card>
  </v-col>
</template>
<script>
export default {
  data: () => ({}),
  methods: {
    access() {
      this.$router.replace({name: 'Login'})
    }
  }
}
</script>
